@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Manrope:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  background: #fcfcfc;
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button,
a,
input[type="submit"] {
  all: unset;
  cursor: pointer;
}
input[type="email"],
input[type="text"],
input[type="password"],
input[type="date"],
input[type="time"],
input[type="search"],
textarea,
select {
  all: unset;
  width: -webkit-fill-available;
  cursor: text;
}
/* Change the white to any color */
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

#loader {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 999999999 !important;
}
.preloader {
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: #f15f22;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  background-color: #fff;
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}
.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}
@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
  }
}

.PageContainer {
  display: flex;
  overflow: scroll;
}
.PageContainer .page-right {
  padding: 20px;
  background-color: #fcfcfc;
  margin-left: 210px;
}
@media screen and (max-width: 769px) {
  .PageContainer .page-right {
    margin-left: 60px;
    overflow-x: scroll;
  }
  .page-right .dashboard-top {
    flex-direction: column;
    gap: 20px !important;
    position: sticky;
    left: 0;
  }
}

/* ======= LOGIN PAGE =======  */
/* ======= LOGIN PAGE =======  */
#login-container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}
#login-container .left {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000c20;
}
#login-container .left .logo {
  width: 180px;
  position: absolute;
  top: 50px;
  left: 50px;
}
#login-container .left img {
  width: 400px;
}
#login-container .right {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
#login-container .login-form {
  width: 50%;
}
#login-container .right form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 40px 0;
}
#login-container .login-form h2 {
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
}
#login-container .input-item,
#login-container .input-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 12px 15px;
}
#login-container label {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 5px;
  color: #a7a7a7;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}
#login-container .input-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.primary-button {
  border-radius: 5px;
  background: #ff5f13;
  width: 100%;
  padding: 10px;
  text-align: center;
  align-self: center;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#login-container button {
  border-radius: 8px;
  background: #ff5f13;
  width: 60%;
  padding: 10px;
  text-align: center;
  align-self: center;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#login-container .help {
  font-family: Roboto;

  position: absolute;
  bottom: 20px;
  right: 50px;
}
#login-container .help a {
  color: #ff5f13;
}
@media screen and (max-width: 769px) {
  #login-container {
    background-color: #000c20;
  }
  #login-container .left .logo {
    width: 100px;
  }
  #login-container button {
    width: 90%;
  }
  #login-container .left,
  #login-container .right {
    flex: none;
  }
  #login-container .login-form h2 {
    color: #a7a7a7;
    font-size: 30px;
    font-weight: 600;
  }
  #login-container .left img {
    width: 200px;
    position: absolute;
    top: 25vw;
    left: calc(50% / 2);
  }
  #login-container .right {
    position: absolute;
    top: 450px;
    left: 0px;
  }
  #login-container .login-form {
    position: absolute;
    left: 20vw;
    width: auto;
  }
  #login-container input[type="submit"] {
    align-self: flex-start;
    width: 50%;
  }
  #login-container .help {
    position: absolute;
    bottom: 0px;
    right: 5px;
  }
}

/* ======= Top Bar ====== */
/* ======= Top Bar ====== */
#header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: #000c20;
  color: #ffffff;
  padding: 10px 50px;
  z-index: 999;
  align-items: center;
}
#header .header-right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.send-load {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: #ff5f13;
  color: #ffffff;
  padding: 8px 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.border-btn {
  background: none;
  color: #ff5f13;
  border: 1px solid #ff5f13;
}
.border-btn:hover {
  background: #ff5f13;
  color: #fff;
}
#header .header-right .load-icon {
  font-size: 22px;
  margin-top: 5px;
  font-weight: 700;
}
.mail-icon,
.notification-icon {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  font-size: 22px;
  width: 25px;
  text-align: center;
  height: 25px;
}
#header .header-right img {
  width: 45px;
  height: 45px;
  border-radius: 8px;
  margin-top: 5px;
  text-align: center;
  object-fit: cover;
}
#header .about {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
#header .about .user {
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}
#header .about .email {
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
#header .header-left {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 769px) {
  #header {
    padding: 10px;
  }
  #header .header-left img {
    width: 80px;
  }
  .hide-text {
    display: none;
  }
  #header .header-right {
    gap: 10px;
  }
  #header .header-right .send-load {
    padding: 8px;
    width: 20px;
    height: 20px;
    justify-content: center;
  }
  #header .header-right .send-load img {
    display: block;
    margin: 0;
    width: 20px !important;
    height: 20px !important;
  }
  #header .header-right .load-icon {
    font-size: 20px;
    margin-top: 5px;
    font-weight: 700;
  }
  .mail-icon,
  .notification-icon {
    padding: 8px;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  #header .header-right img {
    display: none;
  }
  #header .header-right .about {
    display: flex;
    font-size: 10px;
  }
  #header .about .user {
    font-size: 10px;
  }
}

/* ======= Left Nav Bar ======= */
/* ======= Left Nav Bar ======= */
#nav {
  font-style: normal;
  width: fit-content;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  z-index: 999;
}
#leftBar ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 65px 10px 10px 10px;
  margin: 0;
}
#leftBar li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  width: 150px;
  padding: 10px 20px;
  color: #84828a;
  border-radius: 8px;
  font-family: "Roboto", serif;
}
#leftBar li:hover img {
  filter: invert(43%) sepia(98%) saturate(2284%) hue-rotate(352deg)
    brightness(101%) contrast(101%);
}
#leftBar li:hover {
  background-color: #ffede4;
  color: #ff5f13;
  transition: all 0.3s ease;
}
.active li {
  background: #ffede4;
}
.active .menu-icon,
.active span,
.active img {
  color: #ff5f13;
  font-weight: 600;
  filter: invert(43%) sepia(98%) saturate(2284%) hue-rotate(352deg)
    brightness(101%) contrast(101%);
}
.menu-icon {
  font-size: 25px;
  margin-top: 5px;
}
.menu {
  display: none;
}

@media screen and (max-width: 769px) {
  #nav {
    background: rgba(255, 255, 255);
  }
  #leftBar li {
    width: auto;
    padding: 5px 10px;
  }
  #leftBar {
    display: none;
  }
  .PageContainer .page-right {
    margin: 0;
  }
  .menu {
    display: block;
  }
}
/* ====== Dashboard Page ======= */
/* ====== Dashboard Page ======= */
.page-right {
  width: 100%;
}
.page-right .dashboard-top {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 20px 0;
}
.dashboard-top #drop-location-container {
  display: flex;
  flex-direction: column;
  flex: 0.5;
}
.dashboard-top .ArrivalTimeContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
}
#drop-location-container h3,
.ArrivalTimeContainer h3 {
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-weight: 600;
}
.drop-location-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 1px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  overflow: hidden;
}
.drop-location-box h4 {
  color: rgba(0, 0, 0, 0.79);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.drop-location-box ul {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 8px 0;
  gap: 10px;
}
.drop-location-box li {
  font-family: "Roboto", sans-serif;
  list-style-type: disc;
  color: #0d0d0d;

  font-weight: 400;
  font-size: 13px;
}
.drop-location-box .see-all {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #ff5f13;
  font-size: 15px;
  font-weight: 600;
}

.drop-location-box li {
  /* list-style: none; */
}

.drop-location-box .item .bottom {
  display: flex;
  justify-content: space-between;
}

/*Arrival Time Container*/
.Arrivalbox {
  border-radius: 10px;
  /* background: hsl(216, 50%, 56%); */
  background-color: #2060c6;
  padding: 20px;
  margin: 20px 0;
  /* height: -webkit-fill-available; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Arrivalbox .top-right img {
  width: 140px;
}

.Arrivalbox .top {
  padding: 40px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Arrivalbox .top-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Arrivalbox .top .top-left h3 {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}
.Arrivalbox .top .top-left h4 {
  font-size: 20px;
  color: #c9c9c9;
}
.Arrivalbox .top .top-left p {
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.Arrivalbox .top .top-left p .job-type {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}
.Arrivalbox .top .top-left p > span {
  font-size: 12px;
  align-self: flex-end;

  font-weight: 600;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}
.Arrivalbox .bottom ul {
  padding-left: 0;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: space-between;
}
.Arrivalbox .bottom ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;

  font-family: "Roboto", sans-serif;
}
.Arrivalbox .list-icon {
  padding: 0px 5px 0 0;
}
.Arrivalbox .list-In-Progress {
  color: #ff5f13;
}

.Arrivalbox .list-Completed {
  color: #447b18;
}
.Arrivalbox .list-Cancelled {
  color: #d60c0c;
}
.Arrivalbox .list-Scheduled {
  color: #9c73ff;
}

/*JOb List Dashbaord */
.dashboard-bottom {
  margin: 20px 0;
}

.JobListContainer table {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 1.5rem;

  /* border-spacing: 10px; */
}
.JobListContainer .action {
  cursor: pointer;
}
.JobListContainer table .location {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  color: #224f9b;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.26px;
  text-align: left;
}
.JobListContainer table tr {
  border-radius: 5px;
  outline: 1px solid #e9eff4;
  background: #fff;
  width: 100%;
  cursor: pointer;
}
.JobListContainer table th {
  padding: 0 10px;
  color: #878790;

  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* width:max-content; */
}
.JobListContainer table td {
  font-family: "Roboto", sans-serif;

  padding: 10px;
  color: #0d0d0d;
}
.JobListContainer .email > span {
  width: 100px;
  overflow: hidden;
  display: block;
}
.JobListContainer table .view-icon {
  padding: 12px;
  background-color: #dedef0;
  border-radius: 50%;
  /* cursor: pointer; */
}
.JobListContainer table .view-icon-delete {
  padding: 12px;
  background-color: #ffede4;
  color: rgb(255, 95, 19);
  border-radius: 50%;
  /* cursor: pointer; */
}
.JobListContainer table .download-icon {
  padding: 8px;
  width: 20px;
  background-color: #dedef0;
  border-radius: 50%;
  /* cursor: pointer; */
}
.JobListContainer table .status-p,
.In-Progress {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background: #ff5f13;
}
.JobListContainer table .status-can,
.Cancelled {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background: #d60c0c;
  display: inline-block;
  width: -webkit-fill-available;
}
.JobListContainer table .status-c,
.Completed {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background: #447b18;
  display: inline-block;
  width: -webkit-fill-available;
}
.JobListContainer table .status-c,
.Scheduled {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background: #784edb;
  display: inline-block;
  width: -webkit-fill-available;
}
.JobListContainer table .company {
  display: flex;
  align-items: center;
  gap: 8px;
}
.JobListContainer .company-logo {
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}
.client_name {
  cursor: pointer;
}
.swiper {
  width: 550px !important;
  height: auto !important;
}

.swiper-pagination-bullet {
  background: #ff6803 !important;
}
.swiper-pagination-bullet-active {
  background: #e76308 !important;
}

@media screen and (max-width: 769px) {
  .Arrivalbox {
    gap: 30px;
  }
  .Arrivalbox .top {
    padding: 0;
  }
  .Arrivalbox .top .top-left h3 {
    font-size: 18px;
  }
  .Arrivalbox .top .top-left h4 {
    font-size: 16px;
  }
  .Arrivalbox .top .top-left p .job-type {
    font-size: 14px;
  }
  .Arrivalbox .top-left {
    gap: 20px;
  }
  .Arrivalbox .top-right img {
    width: 80px;
  }
  .Arrivalbox .bottom ul {
    justify-content: space-between;
    gap: 20px;
    padding: 0;
    margin-bottom: 0;
  }
  .Arrivalbox .bottom ul li {
    font-size: 12px;
  }
  .swiper {
    width: 325px !important;
    margin: 0 !important;
  }
  .swiper-slide {
    /* width: fit-content !important; */
    width: 160px !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 0% !important;
  }
  .JobListContainer table {
    overflow-y: scroll;
    display: block;
    width: max-content;
  }
  .JobListContainer .company-logo {
    width: 20px;
    height: 20px;
  }
  .JobListContainer table td {
    padding: 10px;
    font-size: 13px;
  }
  .JobListContainer table .location {
    width: 150px;
  }
}
.swiper-wrapper {
  padding: 0 0 30px 0 !important;
  /* justify-content: center !important; */
}
.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;
  flex-shrink: 0 !important;
  width: 40% !important;
  /* Center slide text vertically */
}
@media screen and (max-width: 768px) {
  .swiper-slide {
    width: 75% !important;
  }
}
.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.slide1 {
  color: #ffffff;
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide2 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide3 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide4 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide5 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide6 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide7 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide8 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide9 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide10 {
  height: auto !important;
  padding: 30px 0;
  background-image: url("../public/Images/bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slide {
  display: flex !important;
  flex-direction: column !important;
  align-items: self-start !important;
  padding: 20px !important;
  gap: 10px !important;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.slide .top h3 {
  font-family: "roboto", sans-serif !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}
.slide .top p {
  color: #f0f0f0;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
}
.slide .middle {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 5px 0 0 15px;
  /* border-left: 1px dashed black; */
  font-size: 15px;
}
.slide .middle tr {
  display: flex;
  /* align-items: center; */
  padding: 5px 0;
}
.slide .middle p {
  display: flex;
  align-items: center;
  color: #e4e4e4;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}
.slide .bottom {
  display: flex;
  gap: 20px;
  font-size: 18px;
}
.slide .bottom span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  display: flex;
  gap: 5px;
  font-weight: 400;
  color: #e4e4e4;
  align-items: center;
}
.slide .middle .location-icon1 {
  font-size: 20px;
  /* padding-bottom: -5px; */
}
.slide .middle .location-icon2 {
  font-size: 20px;
  /* padding-bottom: -5px; */
  color: #f92e2e;
}
@media screen and (max-width: 1180px) {
  .page-right .dashboard-top {
    display: flex;
    flex-direction: column;

    gap: 50px;
  }
  /* .swiper {
		width: 700px !important;
	} */
  .JobListContainer table {
    overflow-y: scroll;
    /* display: block; */
    width: max-content;
  }
  #JobHistroyTopBar,
  .client-list-topbar {
    justify-content: flex-start !important;
    gap: 150px;
  }
}
/*===== JOB HISTORY PAGE =======*/
/*===== JOB HISTORY PAGE =======*/
#JobHistroyTopBar h3 {
  color: rgba(0, 0, 0, 0.9);
  font-family: "Inter", sans-serif;
}
#JobHistroyTopBar,
.client-list-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: sticky;
  left: 0;
}

#JobHistroyTopBar .right {
  display: flex;
  gap: 20px;
}
#JobHistroyTopBar .right span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.border-button {
  font-family: "Roboto", sans-serif;

  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  border: 1px solid #ff5f13;
  color: #ff5f13;
  font-weight: 500;
  padding: 8px 15px;
}
@media screen and (max-width: 768px) {
  .border-button,
  .send-load {
    padding: 7px 25px;
  }
  .filter-btn {
    padding: 7px 10px;
  }
  #JobHistroyTopBar,
  .client-list-topbar {
    justify-content: space-between !important;
    gap: 0px;
  }
}

/* .JobListContainer table .location {
  font-size: 13px;
} */

/* REACT CALANDER  */
.react-calendar {
  border: none !important;
  padding: 5px 10px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  width: 300px !important;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__navigation button {
  text-align: center !important;
}
.react-calendar__tile--active {
  color: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: 50px;
}
.react-calendar__tile--now {
  background-color: rgb(255, 153, 153) !important;
  color: #ffffff !important;
}
/*Filter Box*/
/* .FilterBox{
  border-radius: 8px;
background: #FFF;
box-shadow: 3px 4px 33px 0px rgba(0, 0, 0, 0.10);
} */
.FilterBox ul {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 20px;
}
.filter-modal .react-responsive-modal-modal {
  width: 35% !important;
}
.FilterBox ul li {
  list-style: none;
  border-radius: 5px;
  border: 0.2px solid #e4e4e4;
  background: #fff;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.04);
  padding: 10px 20px;
  font-weight: 600;
  color: #5b5b5b;
  cursor: pointer;
}
.FilterBox ul li:hover {
  background: #ffede4;
  color: #ff5f13;
  border: 0.2px solid #ff5f13;
}
.react-responsive-modal-closeButton {
  right: 2px !important;
  top: 2px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 50vw !important;
}
.react-responsive-modal-modal {
  border-radius: 8px !important;
  top: 50px !important;
  left: 5% !important;
  padding: 20px !important;
  max-width: 100% !important;
  display: inline-block !important;
}

/*Date filter*/
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: #db3a3e !important;
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  /* border: 0px solid #db3a3e !important; */
  color: #db3a3e !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #db3a3e !important;
}
.rdrDateDisplayWrapper,
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: #ffede4 !important;
}
@media screen and (max-width: 768px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 80vw !important;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column !important;
  }
  .rdrMonths {
    flex-direction: column !important;
  }
  .rdrMonth {
    width: -webkit-fill-available !important;
  }
  .react-responsive-modal-modal {
    left: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  #JobHistroyTopBar .right {
    gap: 10px;
  }
}

/*====== JOB DETAILS PAGE ======*/
/*====== JOB DETAILS PAGE ======*/
.details > h3 {
  color: rgba(0, 0, 0, 0.9);

  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.details,
.status {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 50px 40px 50px;
}

.details .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 10px;
}
.details .row .box {
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: -webkit-fill-available;
  /* justify-content: center; */
}
.details .row .box > div {
  display: flex;
  align-items: center;
}
select {
  -webkit-appearance: auto;
}
.details .row label {
  margin: 0 10px;

  color: #a7a7a7;

  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}
.details .row .input-box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.05);
  color: #666;
  margin: 0 10px;
}
.photo-box {
  /* padding: 5px; */
  cursor: pointer;
}
.photo-box {
  border: 1px solid #e4e4e4;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.photo-box .preview-img {
  border: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.photo-box .camera-img {
  position: relative;
  bottom: -42%;
  left: 70%;
}
.upload-logo {
  color: #0d0d0d;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0225rem;
}
.createAdmin {
  margin-top: 30px;
}
.createAdmin > h3 {
  color: rgba(0, 0, 0, 0.9);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 122.222% */
}
.admin-list {
  padding: 20px 10px;
}
.admin-list > div {
  display: flex;
  gap: 5px;
  margin: 7px 0 7px 10px;
}
.admin-list .admin-list-email {
  color: var(--p2, #000c20);
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0225rem;
}

.details .add-location {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 5px 0;
}
.details .add-location > span {
  color: #a7a7a7;

  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
}
.details .add-location-icon {
  color: #ff5f13;
}
#Job-details .update-btn {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#Job-details .update-btn button {
  padding: 10px 40px;
  border-radius: 8px;
}
.details .input-box #update {
  width: 250px;
  height: max-content;
}
.details .input-box .update-p {
  width: 250px;
  color: #666;
}
.details select {
  width: 100%;
  color: #666;
}
.details .img-prev {
  width: 200px;
}
.details .update-icon {
  background-color: #ffede4;
  color: #ff5f13;
  padding: 10px;
  border-radius: 50%;
}
.row > .box .location-box .close-icon {
  /* visibility:hidden; */
}
.details .row .location-box {
  display: flex;
  align-items: center;
}
#clonelocationbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .details,
  .status {
    margin: 20px 5px;
  }
  .details .row {
    flex-direction: column;
    gap: 25px;
  }
  .details .row .location-box {
    display: flex;
    /* flex-direction: column; */
    overflow-x: scroll;
    /* align-items: flex-start !important; */
    gap: 10px !important;
  }
  .details .row .location-box .location-arrow {
    /* transform: rotate(90deg); */
  }
  .details .row .pod,
  .update {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 10px;
  }
  .details .row .input-box {
    width: -webkit-fill-available;
  }
}
/*===== CLIENT LIST =====*/
.client-list-topbar .right {
  border-radius: 44px;
  border: 1px solid #ff5f13;
  padding: 8px 20px;
  background: #ffede4;
}
.client-list-topbar .right .search-box {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.client-list-topbar input[type="text"]::placeholder {
  color: #ff5f13;
  opacity: 1; /* Firefox */
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.client-list-topbar input[type="text"]::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #ff5f13;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.client-list-show-more {
  /* position: fixed; */
  /* right: 35px; */
  /* display: none; */
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ff5f13; */
  border-radius: 5px;
  padding: 5px 15px;
  gap: 5px;
  background-color: #dedef0;
}
.client-list-show-more > span {
}
@media screen and (max-width: 768px) {
  .client-list-topbar .right {
    width: 125px;
  }
  .client-list-topbar .right .search-box {
    gap: 0px;
  }
}
/*======= LOGOUT ======*/
.logout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 40px 100px;
}
.logout-container h2 {
  color: #000;

  font-family: "Roboto";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
}
.logout-container .buttons {
  display: flex;
  gap: 20px;
}
.logout-container button {
  border-radius: 5px;
  padding: 8px 40px;
  font-family: Poppins;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32.229px; /* 161.143% */
  letter-spacing: 0.6px;
}
@media screen and (max-width: 768px) {
  .logout-container {
    padding: 35px;
  }
  .logout-container h2 {
    font-size: 20px;
    text-align: center;
  }
  .logout-container button {
    padding: 6px 25px;
  }
}
/* Send Load css */
#send-load h3 {
  color: #ff5f13;
  font-size: 25px;
  padding-bottom: 15px;
}
#send-load h4 {
  color: #ff5f13;
  font-size: 18px;
  padding-bottom: 10px;
}
#send-load p {
  font-family: "Roboto", sans-serif;
  padding-bottom: 23px;
  color: #878790;
}
#send-load {
  padding: 30px;
  background-color: #f5f5f5;
}
#send-load form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#send-load form .row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
#send-load form input,
#send-load form select {
  padding: 10px 20px;
  background-color: #fff;
  color: inherit;
}
#send-load form input[type="number"],
#send-load form textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  background-color: #fff;
}
#send-load form .dimension input {
  width: 25%;
}
#load .css-1t4vnk2-MuiDialogContent-root {
  background-color: #f5f5f5 !important;
}
#send-load button {
  width: 95%;
}

@media screen and (max-width: 768px) {
  #send-load {
    padding: 10px;
  }
  #send-load form .row {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  #send-load h3 {
    font-size: 23px;
    padding-bottom: 15px;
  }
  #send-load p {
    padding-bottom: 18px;
    color: #878790;
  }
  #send-load form input[type="number"],
  #send-load form textarea {
    width: auto;
  }
  #send-load form .dimension input {
    width: auto;
  }
}

/* invoice  */
.invoice-container {
  width: 800px;
  height: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  padding: 20px 100px;
  background-color: #f9f9f9;
}

.invoice-container .logo {
  border: 1px solid black;
  flex: 0.4;
  padding: 10px;
  height: 168px;
  position: relative;
}
.invoice-container .logo img {
  position: absolute;
  top: 37%;
  left: 24%;
}

.invoice-header {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invoice-container .company-info p {
  font-weight: 400;
  line-height: 1.5;
}

.company-info {
  flex: 0.6;
  text-align: left;
  padding: 10px;
  border: 1px solid black;
  border-left: none;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  line-height: 1.5;
}

.invoice-to {
  padding: 10px;
  border: 1px solid #a8a6a6;
  flex: 0.6;
  height: 85px;
}
.invoice-meta {
  flex: 0.4;
  padding: 10px;
  border: 1px solid #a8a6a6;
  border-left: none;
  height: 85px;
}
.invoice-items table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 15px;
  table-layout: fixed;
}
.invoice-container .invoice-items th:nth-child(1) {
  width: 50px;
}
.invoice-container .invoice-items th:nth-child(3),
.invoice-container .invoice-items th:nth-child(4) {
  width: 100px;
  text-align: center;
}
.invoice-items table,
.invoice-items th,
.invoice-items td {
  border: 1px solid #666565;
  padding: 10px;
}

.invoice-items th {
  background-color: #c8d8be;
}

.total-label {
  text-align: center;
}

.invoice-footer {
  text-align: center;
  margin-top: 20px;
}
.invoice-gen-form .row {
  display: flex;
}
